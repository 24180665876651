import React from 'react'
import { Helmet } from 'react-helmet'
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import CTASection from '../../components/landing-page/CTASection'
import FooterMenu from '../../components/landing-page/FooterMenu'
import HeaderMenu from '../../components/landing-page/HeaderMenu'
import { useLanguage } from '../../context/LanguageContext'
import translations from '../../context/Translations'
import FooterV2 from '../../components/landing-page/FooterV2'

function TermsAndConditionScreen() {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Aceva GPT</title>
      </Helmet>
      
      <HeaderMenu t={t} />
      <div className="container">
        <div className="max-w-5xl mx-auto py-8 sm:py-12">
          <div className="text-center mb-12 bg-gray-50 p-6 sm:p-8 rounded-lg">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">Terms and Conditions</h1>
          <p className="text-base sm:text-lg text-gray-600">Please review these Terms and Conditions (Terms) carefully before using our services, which includes the Website, Widget, and API (collectively referred to as ‘Service’). By accessing or using our Service, you agree to comply with and be bound by these Terms and our Privacy Policy. If you disagree with any part of these Terms, you must not use our Service.</p>
        </div>

        <div className="space-y-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Acceptance of Terms</h2>
          <p className='text-base text-gray-600'>By accessing or using our Service, you agree to these Terms and the Privacy Policy. If you do not agree with these Terms, do not use the Service.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Billing and Subscriptions</h2>
          <div className="space-y-4 text-gray-600">
            <p className='text-base text-gray-600'>Subscription Plans: Access to our product is offered through monthly and yearly subscription plans. Full pricing details are available on our Pricing Page.</p>
            <p className='text-base text-gray-600'>No Refund Policy: Due to the nature of our product, all payments made are final, and refunds, either partial or full, will not be issued.</p>
            <p className='text-base text-gray-600'>Cancellation: You may cancel your subscription at any time. Your account will retain access to the Service until the end of the current billing cycle, and no further charges will be made thereafter.</p>
            <p className='text-base text-gray-600'>Changes to Pricing and Services: We reserve the right to change our pricing, pricing policies, features, and access restrictions at any time. Users will be notified of any such changes; however, notification may not occur at least 30 days in advance.</p>
          </div>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Emails and Communications</h2>
          <p className='text-base text-gray-600'>Account Communications: Your email may be used to send important updates regarding your account, service-related notifications, and announcements about new features.</p>
          <p className='text-base text-gray-600'>Marketing Emails: By using our Service, you agree to receive marketing communications, which may include newsletters and promotional offers. If required by applicable law, additional steps to confirm consent may be implemented. You may opt-out of marketing emails at any time by following the unsubscribe instructions provided in each email.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Use of Service</h2>
          <p className='text-base text-gray-600'>Acceptable Use: You agree to use the Service only for lawful purposes. Prohibited use, including unauthorized access, reverse engineering, or disrupting the Service, is strictly prohibited.</p>
          <p className='text-base text-gray-600'>Account Responsibility: Users are responsible for maintaining the confidentiality of their account credentials and any activities conducted through their account.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Intellectual Property</h2>
          <p className='text-base text-gray-600'>All trademarks, service marks, and content provided through the Service are the exclusive property of Aceva. Users are prohibited from reproducing or distributing this content without prior written consent.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Data Privacy</h2>
          <p className='text-base text-gray-600'>We handle user data in accordance with our Privacy Policy. By using the Service, you consent to the data collection, use, and sharing practices described in the Privacy Policy.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Changes to Terms</h2>
          <p className='text-base text-gray-600'>We reserve the right to amend these Terms and Conditions at any time. Changes will be communicated through email or published on our website. Continued use of the Service constitutes acceptance of the revised terms.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Dispute Resolution</h2>
          <p className='text-base text-gray-600'>Disputes arising from these terms or your use of the Service will be governed by Malaysian law. Resolution will primarily occur within the jurisdiction of Malaysia unless otherwise required by applicable international regulations.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">9. Termination</h2>
          <p className='text-base text-gray-600'>We reserve the right to terminate or suspend your account without prior notice if you violate these Terms or engage in activities that harm the Service or other users.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">10. Liability</h2>
          <p className='text-base text-gray-600'>Aceva disclaims all implied warranties to the maximum extent permitted by law. Any limitation of liability will be subject to further discussion and outlined in subsequent amendments to these terms, if necessary.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">11. Indemnity</h2>
          <p className='text-base text-gray-600'>You agree to indemnify and hold Aceva harmless from any claims, damages, or expenses arising from your use of the Service or violation of these Terms.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">12. Third-Party Services</h2>
          <p className='text-base text-gray-600'>Certain features of the Service may rely on third-party tools or integrations. By using such features, you agree to comply with the respective third-party terms of use. Aceva is not liable for issues arising from third-party services.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">13. Disclaimers</h2>
          <p className='text-base text-gray-600'>The Service is provided ‘as is’ and ‘as available’ without any warranties, express or implied. We do not guarantee the availability, reliability, or performance of the Service and are not responsible for any disruptions or damages resulting from the use of the Service.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">14. Privacy Policy</h2>
          <p className='text-base text-gray-600'>Your use of the Service is also governed by our Privacy Policy which outlines how we collect, use, and protect your personal data.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">15. Force Majeure</h2>
          <p className='text-base text-gray-600'>Aceva is not responsible for delays or failure to perform resulting from causes beyond our reasonable control, including natural disasters, strikes, or technical failures.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">16. Entire Agreement</h2>
          <p className='text-base text-gray-600'>These Terms and our Privacy Policy constitute the entire agreement between you and ACEVA regarding the use of the Service. Any previous agreements or understandings are superseded by this agreement.</p>
        </div>
        </div>
      </div>
      <CTASection t={t} />
      <FooterV2 t={t} />
    </>
  )
}

export default TermsAndConditionScreen