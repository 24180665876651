import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import RelatedPostSection from '../../components/blog/RelatedPostSection';
import FooterV2 from '../../components/landing-page/FooterV2';
import HeaderMenu from '../../components/landing-page/HeaderMenu';
import sidebarImage from '../../images/bg/aceva.png';
import { useParams } from 'react-router-dom';
import CTASection from '../../components/landing-page/CTASection';
import { Clock } from 'lucide-react';


/**
 * BlogDetailScreen Component
 * Displays a single blog post with full content, navigation, and related posts
 * Fetches data dynamically based on the URL slug
 */
function BlogDetailScreen() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const currentUrl = window.location.href;

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const { data } = await axios.get(
          `https://gptsaas.aceva.com.my/api/blogs/slug/${slug}`
        );
        setPost(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setLoading(false);
      }
    };

    if (slug) {
      fetchBlogPost();
    }
  }, [slug]);

  // Skeleton loader for the blog post
  if (loading) {
    return (
      <>
        <HeaderMenu />
        <main className='bg-gray-50 py-12'>
          <div className='container'>
            <div className='max-w-5xl mx-auto'>
              <div className='lg:col-span-2'>
                <div className='bg-white rounded-lg p-6'>
                  <div className='w-24 h-6 bg-gray-200 animate-pulse rounded-full mb-4' />
                  <div className='space-y-3 mb-6'>
                    <div className='w-3/4 h-8 bg-gray-200 animate-pulse rounded' />
                    <div className='w-1/2 h-4 bg-gray-200 animate-pulse rounded' />
                  </div>
                  <div className='w-full h-[400px] bg-gray-200 animate-pulse rounded-lg mb-8' />
                  <div className='space-y-4'>
                    {[...Array(5)].map((_, i) => (
                      <div
                        key={i}
                        className='w-full h-4 bg-gray-200 animate-pulse rounded'
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <FooterV2 />
      </>
    );
  }

  if (!post) {
    return (
      <>
        <HeaderMenu />
        <main className='bg-gray-50 py-12'>
          <div className='container mx-auto px-4 text-center'>
            <h1 className='text-2xl font-bold text-gray-900'>
              Blog post not found
            </h1>
          </div>
        </main>
        <FooterV2 />
      </>
    );
  }

  return (
    <>
      <HeaderMenu />
      <main className='bg-gray-50 py-10'>
        <div className='container'>
          <div className='max-w-5xl mx-auto'>
            <div className=''>
              <div className='bg-white rounded-lg p-6'>
                <nav className='flex items-center gap-2 text-sm text-gray-500 mb-6 flex-wrap'>
                  <Link to='/' className='hover:text-brand-color'>
                    Home
                  </Link>
                  <span>/</span>
                  <Link to='/blog' className='hover:text-brand-color'>
                    Blog
                  </Link>
                  <span>/</span>
                  <span className='text-gray-700'>{post.title}</span>
                </nav>

                <div className='mb-2'>
                  <h1 className='text-3xl sm:text-4xl leading-[45px] sm:leading-[50px] font-bold text-gray-900 mb-4'>
                    {post.title}
                  </h1>
                  <div className='flex items-center justify-between gap-4 text-sm text-gray-500 mb-6'>
                    <div className='text-gray-500 capitalize flex items-center gap-1'>
                      <Clock className='w-4 h-4' /> 
                      <span className='ml-1'>
                        {new Date(post.createdAt).toLocaleDateString('en-US', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric'
                        }).toLowerCase()}
                      </span>
                    </div>
                  </div>

                  <div className='relative h-auto sm:h-[450px] rounded-lg overflow-hidden'>
                    <img
                      src={post.featuredImage || '/images/support/calendar.png'}
                      alt={post.title}
                      className='w-full h-full object-cover'
                    />
                  </div>
                </div>

                <div
                  className='prose max-w-none descriptions !p-0'
                  dangerouslySetInnerHTML={{ __html: post.content }}
                />

                {post.videoURL && (
                  <div className='mt-8'>
                    <Link
                      to={post.videoURL}
                      className='inline-block px-4 py-2 text-brand-color hover:text-brand-color-bold border border-brand-color rounded-lg'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Watch Related Video
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-7">
          <CTASection />
        </div>

        <RelatedPostSection currentPostId={post.id} />
      </main>
      <FooterV2 />
    </>
  );
}

export default BlogDetailScreen;
