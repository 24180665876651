'use client'

import { ChevronDown } from 'lucide-react'
import { useState } from 'react'

export default function FAQSection({ t }) {
  const [openItems, setOpenItems] = useState(new Set())

  const toggleItem = (index) => {
    setOpenItems((prevOpenItems) => {
      const newOpenItems = new Set(prevOpenItems)
      if (newOpenItems.has(index)) {
        newOpenItems.delete(index)
      } else {
        newOpenItems.add(index)
      }
      return newOpenItems
    })
  }

  if (!t?.faqSection) {
    return null
  }

  const faqItems = t.faqSection.faqs
  const midPoint = Math.ceil(faqItems.length / 2)
  const leftColumnItems = faqItems.slice(0, midPoint)
  const rightColumnItems = faqItems.slice(midPoint)

  const FAQItem = ({ item, index }) => (
    <div className="group bg-white rounded-xl border border-gray-200 transition-all duration-200 hover:shadow-md">
      <button
        className="w-full flex justify-between items-center p-6 cursor-pointer group-hover:bg-gray-50/50 rounded-xl transition-colors"
        onClick={() => toggleItem(index)}
        aria-expanded={openItems.has(index)}
        aria-controls={`faq-answer-${index}`}
      >
        <span className="text-lg font-medium text-gray-900 text-left pr-8">
          {item.question}
        </span>
        <ChevronDown
          className={`w-5 h-5 text-gray-700 transition-transform duration-300 ease-in-out transform ${
            openItems.has(index) ? 'rotate-180' : ''
          }`}
        />
      </button>
      <div
        id={`faq-answer-${index}`}
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          openItems.has(index) ? 'max-h-96' : 'max-h-0'
        }`}
      >
          {/* Render answer with HTML */}
          <div
            className="text-gray-600 text-base p-6 pt-0 html-content leading-7"
            dangerouslySetInnerHTML={{ __html: item.answer }}
          />
        </div>
    </div>
  )

  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center mb-16">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-[45px] !sm:leading-[50px] sm:leading-[80px] max-w-5xl text-center mx-auto bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
            {t.faqSection.title}
          </h2>
          <p className="max-w-[700px] text-gray-600 text-xl md:text-2xl">
            {t.faqSection.description}
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-6 md:gap-10">
          {/* Left Column */}
          <div className="space-y-4">
            {leftColumnItems.map((item, index) => (
              <FAQItem key={index} item={item} index={index} />
            ))}
          </div>
          
          {/* Right Column */}
          <div className="space-y-4">
            {rightColumnItems.map((item, index) => (
              <FAQItem 
                key={index + midPoint} 
                item={item} 
                index={index + midPoint} 
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

