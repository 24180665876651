import { Info } from 'lucide-react';
import React, { useState } from 'react';
import Layout from '../../components/Layout'

function IntegrationScreen({ toggleLoggedInState }) {
  const [formData, setFormData] = useState({
    waAppName: '',
    waAppId: '',
    phoneNumber: '',
    wabaId: '',
    bsp: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Success:', formData);
    localStorage.setItem('whatsapp-b-info', JSON.stringify(formData));
    window.location.href = '/create-whatsapp-chatbot';
  };

  return (
    <Layout>
      <div className="p-6">
        <div className="max-w-3xl mx-auto">
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">WhatsApp Integration</h2>
            <p className="text-gray-600">
              Please enter your details below for the next step. Need assistance? Click on the "I don't have a WABA ID" button, and our ACEVA support team will be glad to help!
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="waAppName" className="block text-sm font-medium text-gray-700 mb-1">
                WhatsApp App Name
              </label>
              <input
                type="text"
                id="waAppName"
                name="waAppName"
                value={formData.waAppName}
                onChange={handleInputChange}
                placeholder="Wipdata AI"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-0 focus:ring-1 focus:ring-brand-color"
              />
            </div>

            <div>
              <label htmlFor="waAppId" className="block text-sm font-medium text-gray-700 mb-1">
                WhatsApp App ID
              </label>
              <input
                type="text"
                id="waAppId"
                name="waAppId"
                value={formData.waAppId}
                onChange={handleInputChange}
                placeholder="1b73dad6-xxxx-xxxx-xxxx-60981da19f70"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-0 focus:ring-1 focus:ring-brand-color"
              />
            </div>

            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="01111234515"
                required
                minLength="9"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-0 focus:ring-1 focus:ring-brand-color"
              />
            </div>

            <div>
              <label htmlFor="wabaId" className="block text-sm font-medium text-gray-700 mb-1">
                WABA ID
              </label>
              <input
                type="text"
                id="wabaId"
                name="wabaId"
                value={formData.wabaId}
                onChange={handleInputChange}
                placeholder="229693900231951"
                required
                minLength="15"
                maxLength="15"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-0 focus:ring-1 focus:ring-brand-color"
              />
            </div>

            <div>
              <label htmlFor="bsp" className="block text-sm font-medium text-gray-700 mb-1">
                Business Solution Provider (BSP)
              </label>
              <input
                type="text"
                id="bsp"
                name="bsp"
                value={formData.bsp}
                onChange={handleInputChange}
                placeholder="Gupshup"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-0 focus:ring-1 focus:ring-brand-color"
              />
            </div>

            <div className="flex flex-col sm:flex-row gap-4 mt-8">
              <button
                type="submit"
                className="px-6 py-2 bg-brand-color text-white rounded-md hover:bg-brand-color-bold focus:outline-0 focus:ring-1 focus:ring-brand-color focus:ring-offset-2"
              >
                Connect
              </button>
              <a
                href="/whatsapp-details"
                className="px-6 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-0 focus:ring-1 focus:ring-gray-500 focus:ring-offset-2 flex items-center justify-center"
              >
                <Info className="w-4 h-4 mr-2" />
                I don't have a WABA ID
              </a>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default IntegrationScreen;
