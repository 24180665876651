export function formatDateToHumanReadable(isoDate) {
    const date = new Date(isoDate);
    
    // Define options for formatting
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    };
  
    // Format the date
    return date.toLocaleDateString('en-US', options);
  }

export function generateUniqueId() {
  const timestamp = new Date().getTime().toString(36); // Convert timestamp to base36
  const randomNum = Math.floor(Math.random() * 900).toString(36); // Generate a base36 random number

  const uniqueId = `SID${timestamp}${randomNum}`; // Concatenate the shortened timestamp and random number

  return uniqueId;
}
