import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PostSkeleton = () => (
  <div className="bg-white rounded-lg overflow-hidden shadow-lg">
    <div className="relative w-full h-48 bg-gray-200 animate-pulse" />
    <div className="p-6 space-y-4">
      <div className="w-24 h-6 bg-gray-200 animate-pulse rounded-full" />
      <div className="space-y-2">
        <div className="w-3/4 h-4 bg-gray-200 animate-pulse rounded" />
        <div className="w-full h-4 bg-gray-200 animate-pulse rounded" />
        <div className="w-2/3 h-4 bg-gray-200 animate-pulse rounded" />
      </div>
      <div className="flex justify-between pt-2">
        <div className="w-32 h-4 bg-gray-200 animate-pulse rounded" />
        <div className="w-24 h-4 bg-gray-200 animate-pulse rounded" />
      </div>
    </div>
  </div>
);

const BlogPostCard = ({ post }) => (
  <article className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:-translate-y-1">
    <div className="relative w-full h-48">
      <img 
        src={post.featuredImage || '/images/support/calendar.png'}
        alt={post.title}
        className="object-cover w-full h-full"
        loading={post.id === 1 ? "eager" : "lazy"}
      />
    </div>
    <div className="p-6">
      {/* <Link 
        to={`/category/${post.category?.slug}`}
        className="inline-block px-3 py-1 mb-4 text-sm font-semibold text-brand-color bg-green-50 rounded-full hover:bg-green-100 transition-colors"
      >
        {post.category?.name}
      </Link> */}
      <h3 className="mb-4">
        <Link 
          to={`/blog/${post.slug}`}
          className="text-xl font-bold text-gray-900 hover:text-brand-color transition-colors duration-200 line-clamp-2"
        >
          {post.title}
        </Link>
      </h3>
      {post.content?.replace(/<[^>]*>/g, '').substring(0, 150) + '...'}
      {/* <div className="flex items-center justify-between text-sm text-gray-500">
        <Link 
          to={`/author/${post.author?.slug}`}
          className="font-medium text-brand-color hover:text-brand-color-bold transition-colors"
        >
          {post.author?.name}
        </Link>
        {post.videoURL && (
          <Link
            to={post.videoURL}
            className="text-brand-color hover:text-brand-color-bold transition-colors"
            target="_blank"
            rel="noopener noreferrer"
          >
            Watch Video
          </Link>
        )}
      </div> */}
    </div>
  </article>
);

BlogPostCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    content: PropTypes.string,
    featuredImage: PropTypes.string,
    videoURL: PropTypes.string,
    category: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    }),
    author: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  }).isRequired
};

const LatestPostSection = ({ posts }) => {
  if (!posts?.length) {
    return null;
  }

  return (
    <section className="bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="mb-8 text-left">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">Latest Blog</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8">
          {posts.map((post) => (
            <BlogPostCard key={post.id} post={post} />
          ))}
        </div>
      </div>
    </section>
  );
};

LatestPostSection.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  }))
};

export default LatestPostSection;