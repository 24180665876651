import React from 'react'
import HeaderMenu from '../components/landing-page/HeaderMenu'
import FooterV2 from '../components/landing-page/FooterV2'
import LiveDemoSection from '../components/landing-page/LiveDemoSection'
import { useLanguage } from '../context/LanguageContext'
import translations from '../context/Translations'

function LiveDemoScreen() {

  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <HeaderMenu t={t} />
        <LiveDemoSection t={t} />
      <FooterV2 t={t} />
    </>
  )
}

export default LiveDemoScreen