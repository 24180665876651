import { Bot, ChevronDown, MessageCircle, Trash2, Zap } from 'lucide-react';
import { useState } from 'react';

const AddOnCard = ({ icon: Icon, title, description, price, color, children, extraMessages, extraWhatsapp, addonPayNow, sPrice }) => (
  <div className="bg-white rounded-xl shadow-sm p-6 transition-all duration-300 hover:shadow-xl border border-gray-100 hover:border-gray-200 relative">
    <div className={`inline-flex items-center justify-center w-12 h-12 rounded-full z-20 mb-6 ${color}`}>
      <Icon className="w-6 h-6 text-white" />
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 mb-5 font-medium text-base">{description}</p>
    {children}
    <div className="flex justify-between items-center mt-5 pt-5 border-t border-gray-100">
      <span className="text-2xl font-bold">{price}</span>
      <button 
        onClick={() => addonPayNow(title, sPrice, 30, extraMessages || extraWhatsapp)}
        className="bg-gray-100 text-gray-800 font-semibold py-2 px-4 rounded-lg hover:bg-gray-200 transition-colors duration-200"
      >
        Add to plan
      </button>
    </div>
  </div>
);

const CustomSelect = ({ options, value, onChange }) => (
  <div className="relative">
    <select
      value={value}
      onChange={onChange}
      className="appearance-none w-full bg-gray-50 border border-gray-200 rounded-md py-3 px-4 pr-8 leading-tight focus:outline-none focus:ring-2 focus:ring-brand-color focus:border-transparent transition-all duration-200"
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option} credits
        </option>
      ))}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <ChevronDown className="w-5 h-5" />
    </div>
  </div>
);

function Addons({addonPayNow}) {
  const [extraWhatsapp, setExtraWhatsapp] = useState("1000");
  const [extraMessages, setExtraMessages] = useState("1000");

  const creditOptions = ["1000", "2000", "3000", "4000", "5000"];

  const standardPricing = {
    "1000": 5,
    "2000": 10,
    "3000": 15,
    "4000": 20,
    "5000": 25,
  };

  const handleAddToPlan = (addon, value) => {
    // Here you would implement the logic to add the addon to the user's plan
    console.log(`Adding ${addon} to plan with value:`, value);
  };

  return (
    <section className='bg-white py-16'>
      <div className="">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-center mb-4 text-gray-800">Add-ons</h2>
        <p className="text-xl text-center mb-12 text-gray-600">Enhance your experience with our powerful add-ons</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <AddOnCard
            addonPayNow={addonPayNow}
            icon={MessageCircle}
            extraWhatsapp={extraWhatsapp}
            extraMessages={extraMessages}
            title="More WhatsApp AI messages"
            description="Increase your WhatsApp AI responses and messages"
            price={`$${standardPricing[extraWhatsapp].toFixed(2)}`}
            sPrice={standardPricing[extraWhatsapp]}
            color="bg-green-500"
            onAdd={() => handleAddToPlan('whatsapp', extraWhatsapp)}
          >
            <p className="text-sm text-gray-600 mb-4 font-medium">$5 per 1000 messages / month</p>
            <CustomSelect
              options={creditOptions}
              value={extraWhatsapp}
              onChange={(e) => setExtraWhatsapp(e.target.value)}
            />
          </AddOnCard>

          <AddOnCard
            addonPayNow={addonPayNow}
            icon={Zap}
            title="More AI responses"
            extraMessages={extraMessages}
            description="Increase your AI chatbot responses"
            price={`$${standardPricing[extraMessages].toFixed(2)}`}
            sPrice={standardPricing[extraMessages]}
            color="bg-yellow-500"
            onAdd={() => handleAddToPlan('ai-responses', extraMessages)}
          >
            <p className="text-sm text-gray-600 mb-4 font-medium">$5 per 1000 responses / month</p>
            <CustomSelect
              options={creditOptions}
              value={extraMessages}
              onChange={(e) => setExtraMessages(e.target.value)}
            />
          </AddOnCard>

          <AddOnCard
            addonPayNow={addonPayNow}
            icon={Trash2}
            title="Remove Aceva's branding"
            description="Remove our branding from your chatbot interface"
            price="$5.00"
            sPrice={5}
            color="bg-red-500"
            onAdd={() => handleAddToPlan('remove-branding', null)}
          >
            <p className="text-sm text-gray-600 mb-4 font-medium py-2"></p>
          </AddOnCard>

          <AddOnCard
            addonPayNow={addonPayNow}
            icon={Bot}
            title="Additional Chatbot"
            description="Scale your business with an additional AI chatbot"
            price="$5.00"
            sPrice={5}
            color="bg-blue-500"
            onAdd={() => handleAddToPlan('new-chatbot', null)}
          >
            <p className="text-sm text-gray-600 mb-4 font-medium">One-time fee per chatbot</p>
          </AddOnCard>

          <AddOnCard
            addonPayNow={addonPayNow}
            icon={Bot}
            title="Build it for you"
            description="A chatbots trained and tailored for you"
            price="$15.00"
            sPrice={15}
            color="bg-blue-500"
          >
            <p className="text-sm text-gray-600 mb-4 font-medium">We develop the chatbot for you</p>
          </AddOnCard>
        </div>
      </div>
    </section>
  );
}

export default Addons;