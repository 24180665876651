import React from 'react'

function LeadGenModal({setModalOpen, leadOptions}) {
  return (
    <>
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-[9999]">
            <div className="bg-white p-7 rounded-lg flex justify-start items-start flex-col max-w-[600px] gap-2">
              {/* <p className="absulate">X</p> */}
              <h2 className="text-2xl font-semibold mb-2">Lead Generation</h2>
              <div className="space-y-4">

                <p className='text-left'>💡 Customize your lead generation questions to capture valuable customer insights and contact information.</p>

                <p className="text-left">💡 These questions can be seamlessly integrated into your chatbot’s conversational flow, making it easy to gather the details you need.</p>

                <p className="text-left">💡 Use this feature to collect information such as name, email, phone number, company name, product/service interests.</p>

                <p className="text-left">💡 The information collected will be sent to your selected communication channel, such as email or WhatsApp, at your preferred time, ensuring smooth and efficient follow-ups.</p>

              </div>
              <button
                onClick={() => setModalOpen(false)}
                className="mt-4 bg-brand-color text-white font-semiBold px-4 py-2 rounded-lg hover:bg-brand-color-dark"
              >
                Close
              </button>
            </div>
          </div>
    </>
  )
}

export default LeadGenModal