import axios from 'axios'
import { motion, useAnimation } from 'framer-motion'
import Cookies from 'js-cookie'
import { ArrowRight, Send, X } from 'lucide-react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { generateUniqueId } from '../../utils/helper'
import { apiUrl } from '../../Variables'

export default function HeroSectionV3({t}) {
  const [messages, setMessages] = useState([])
  const [inputValue, setInputValue] = useState("")
  const controls = useAnimation()
  const chatContainerRef = useRef(null)
  const inputRef = useRef(null)
  const [userInfo, setUserInfo] = useState(null)
  const [botInfo, setBotInfo] = useState(null)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [botConfigInfo, setBotConfigInfo] = useState(null)
  const [ip, setIp] = useState(Cookies.get('senderId'))
  const [loading, setLoading] = useState(false)
  const [botRunningStatusObj, setBotRunningStatusObj] = useState({active: false, runningOn: null})
  const [showVideoModal, setShowVideoModal] = useState(false)


  useEffect(() => {
    const fetchData = async () => {
      // Simulating user authentication. Replace with actual auth logic if needed.
      const mockUserId = '651e6d7e21fe7b6c2f17c435'
      const mockBotId = '651ea15c13ff0d95a4dfe3d0'

      try {
        const userRes = await axios.post(`${apiUrl}/user/token_by_id`, { userId: mockUserId })
        setUserInfo(userRes.data)

        const botConfigRes = await axios.get(`${apiUrl}/botConfig/${mockBotId}`, {
          headers: { Authorization: `Bearer ${userRes.data?.token}` }
        })
        setBotConfigInfo(botConfigRes.data[0]?.configData)

        const botRes = await axios.get(`${apiUrl}/chatbot/${mockBotId}`, {
          headers: { Authorization: `Bearer ${userRes.data?.token}` }
        })
        setBotInfo(botRes.data[0])

        if (!Cookies.get('senderId')) {
          const uniqueId = generateUniqueId()
          Cookies.set('senderId', uniqueId, { expires: 365, SameSite: "None", Secure: true, Priority: 'High' })
          setIp(uniqueId);
        }

        // Fetch initial chat history
        const chatRes = await axios.get(`${apiUrl}/chats/${mockBotId}/${ip}?date=${currentDate.toISOString()}`, {
          headers: { Authorization: `Bearer ${userRes.data?.token}` }
        })
        setMessages(chatRes.data.chats);

      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to initialize chat');
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2 }
    }))
  }, [controls])

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [messages])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (inputValue.trim()) {
      setLoading(true)
      const newMessage = {time: Date.now(), user: inputValue, bot: {text: ''}}
      setMessages(prev => [...prev || [], newMessage])
      setInputValue("")

      try {
        const headers = { Authorization: `Bearer ${userInfo.token}` }
        const data = {
          userId: userInfo._id,
          chatbotId: botInfo._id,
          senderId: ip,
          message: inputValue,
          language: {
              "value": "en-GB",
              "label": "English (UK)"
          }
        }

        const res = await axios.post(`${apiUrl}/chats?date=${currentDate.toISOString()}`, data, { headers })
        
        setMessages(res.data.chats)

      } catch (error) {
        console.error('Error sending message:', error)
        toast.error(`Failed: ${error?.message || error?.response?.data}`)
      } finally {
        setLoading(false)
        inputRef.current.focus()
      }
    }
  }

  return (
    <section className="py-[40px] bg-gradient-to-br from-indigo-100 via-purple-50 to-teal-100">
      {/* video modal */}
      {showVideoModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4">
          <motion.div 
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            className="relative w-full max-w-4xl"
          >
            <button 
              onClick={() => setShowVideoModal(false)}
              className="absolute -top-10 right-0 text-white hover:text-gray-300 transition-colors"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
            <div className="relative pt-[56.25%]">
              <iframe
                className="absolute inset-0 w-full h-full rounded-lg"
                src="https://www.youtube.com/embed/6T14pRBNhLA?si=H6k1ylWNK6tbN3fC"
                title="Demo Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </motion.div>
        </div>
      )}

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
        <div className="grid gap-4 lg:gap-4 md:grid-cols-2 items-center !sm:pt-10 !md:pt-10">
          <div className="max-w-xl mx-auto md:mx-0 !sm:pt-10">
            <h1 className='text-base font-medium text-brand-color mb-2'>{t?.heroSectionV3?.topTitle}</h1>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="mb-4 sm:mb-6 text-4xl sm:text-4xl lg:text-4xl font-bold text-gray-900 !leading-[45px] sm:!leading-[1.2]  sm:mt-5 xs:mt-4"
            >
              <span >{t?.heroSectionV3?.title[0]} </span>
              
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="mb-6 sm:mb-8 text-base sm:text-lg lg:text-base text-gray-600 !leading-relaxed"
            >
              {t?.heroSectionV3?.subTitle}
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="flex flex-col sm:flex-row gap-4"
            >
              <Link to="/register" className="w-full sm:w-auto">
                <button className="w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-4 text-white bg-brand-color rounded-full shadow-lg transition-all duration-300 hover:bg-brand-color-bold hover:shadow-xl flex items-center justify-center transform hover:scale-105 text-base sm:text-base font-medium"> 
                  {t?.heroSectionV3?.buttons.getStarted}
                  <ArrowRight className="ml-2 text-white" size={20} />
                </button>
              </Link>
            </motion.div>
            {/* <p className='mt-4 sm:mt-5 text-gray-400 text-xs sm:text-sm'>{t?.heroSectionV3?.smallText}!</p> */}
          </div>
          <div className="relative mt-8 md:mt-0">
            <div className="absolute inset-0 bg-gradient-to-br from-brand-color to-brand-color-bold rounded-2xl transform -rotate-6"></div>
            <div className="relative bg-white p-4 sm:p-6 lg:p-8 rounded-2xl shadow-2xl">
              <div className="mb-4 sm:mb-6 flex justify-between items-center">
                <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-800">AI Chat Demo</h2>
                <div className="flex space-x-2">
                  <div className="w-2 h-2 sm:w-3 sm:h-3 bg-red-500 rounded-full"></div>
                  <div className="w-2 h-2 sm:w-3 sm:h-3 bg-yellow-500 rounded-full"></div>
                  <div className="w-2 h-2 sm:w-3 sm:h-3 bg-green-500 rounded-full"></div>
                </div>
              </div>
              <div 
                ref={chatContainerRef}
                className="h-48 sm:h-64 lg:h-80 overflow-y-auto mb-4 p-3 sm:p-4 bg-gray-50 rounded-lg"
              >
                <ChatMessage message={{time: Date.now(), user: "", bot: {text: "Hi there! How can I help you today?"}}} />
                {messages?.map((message, index) => (
                  <ChatMessage key={index} message={message} />
                ))}
                {loading && <ChatMessage message={{bot: {text: "Typing..."}}} />}
              </div>
              <form onSubmit={handleSubmit} className="flex items-center">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={botConfigInfo?.placeholder || "Type your message..."}
                  className="flex-1 px-3 sm:px-4 py-2 sm:py-3 text-sm sm:text-base rounded-l-full border border-gray-300 focus:outline-0 focus:ring-0 focus:ring-brand-color focus:border-transparent"
                  ref={inputRef}
                />
                <button
                  type="submit"
                  className="px-4 sm:px-6 !py-[15px] !sm:py-[15px] bg-brand-color text-white rounded-r-full hover:bg-brand-color-bold transition-colors duration-300"
                  disabled={loading || botRunningStatusObj.active}
                  aria-label="Send message"
                >
                  <Send className="text-white w-4 h-4 sm:w-5 sm:h-5" />
                </button>
              </form>
            </div>
          </div>
        </div>
        
        {/* <div className="mt-16 sm:mt-20 lg:mt-24">
          <h2 className="mb-8 sm:mb-12 text-xl sm:text-2xl lg:text-3xl font-bold text-center text-gray-800">{t?.heroSectionV3?.whyChooseUsGroup.title}</h2>
          <div className="grid gap-6 sm:gap-8 md:grid-cols-3">
            <motion.div custom={0} animate={controls}>
              <FeatureCard
                icon={Zap}
                title={t?.heroSectionV3?.whyChooseUsGroup.items[0].title}
                description={t?.heroSectionV3?.whyChooseUsGroup.items[0].description}
              />
            </motion.div>
            <motion.div custom={1} animate={controls}>
              <FeatureCard
                icon={MessageSquare}
                title={t?.heroSectionV3?.whyChooseUsGroup.items[1].title}
                description={t?.heroSectionV3?.whyChooseUsGroup.items[1].description}
              />
            </motion.div>
            <motion.div custom={2} animate={controls}>
              <FeatureCard
                icon={BarChart}
                title={t?.heroSectionV3?.whyChooseUsGroup.items[2].title}
                description={t?.heroSectionV3?.whyChooseUsGroup.items[2].description}
              />
            </motion.div>
          </div>
        </div> */}
      </div>
    </section>
  )
}

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    className="flex flex-col items-center p-4 sm:p-6 bg-white rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl h-full"
    whileHover={{ scale: 1.03 }}
    transition={{ type: 'spring', stiffness: 300 }}
  >
    <div className="p-3 sm:p-4 mb-3 sm:mb-4 text-white bg-green-50 rounded-full">
      <Icon className="text-brand-color w-5 h-5 sm:w-7 sm:h-7" />
    </div>
    <h3 className="mb-2 text-lg sm:text-xl font-semibold text-gray-800 text-center">{title}</h3>
    <p className="text-center text-sm sm:text-base text-gray-600">{description}</p>
  </motion.div>
)

const ChatMessage = ({ message }) => (
  <Fragment>
    {message.user && (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className='flex justify-end mb-3 sm:mb-4'
      >
        <div
          className={`px-3 sm:px-4 py-2 rounded-lg bg-brand-color text-white w-fit max-w-[80%] text-sm sm:text-base break-words`}
        >
          {message.user}
        </div>
      </motion.div>
    )}
    {message.bot && message.bot.text && (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className='flex justify-start mb-3 sm:mb-4'
      >
        <div
          className={`px-3 sm:px-4 py-2 rounded-lg bg-gray-200 text-gray-800 w-fit max-w-[80%] text-sm sm:text-base break-words`}
          dangerouslySetInnerHTML={{ __html: message?.bot?.text?.replace(/\n/g, '<br />') }}
        >
          {/* {message.bot.text} */}
        </div>
      </motion.div>
    )}
  </Fragment>
)
