import React from 'react'
import FooterMenu from '../components/landing-page/FooterMenu'
import HeaderMenu from '../components/landing-page/HeaderMenu'
import LandingStepSection from '../components/landing-page/LandingStepSection'
import { useLanguage } from '../context/LanguageContext'
import translations from '../context/Translations'
import FooterV2 from '../components/landing-page/FooterV2'
import ChatbotCreateSteps from '../components/landing-page/ChatbotCreateSteps'

import video from '../videos/how.mp4'
import CTASection from '../components/landing-page/CTASection'

function HowIsWorkScreen() {

  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <HeaderMenu t={t} />
        {/* <LandingStepSection t={t} /> */}
        <ChatbotCreateSteps t={t} />


        <section className='py-16'>
          <div className="container">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 text-gray-800">{t?.videoTitle}</h2>

            <video src={video} className='w-full h-auto rounded-lg' controls></video>
          </div>
        </section>

      <CTASection />

      <FooterV2 t={t} />
    </>
  )
}

export default HowIsWorkScreen