import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { apiUrl } from '../../Variables';
import PlainHeader from '../../components/PlainHeader';
import TrainFiles from '../../components/steps/TrainFiles';
import TrainLinks from '../../components/steps/TrainLinks';

function UpdateChatbotFileScreen({toggleLoggedInState}) {

  const {id} = useParams(); 
  const [singleData, setSingleData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    const featchData = async () => {
      try {
        setLoading(true)
        const {data} = await axios.get(`${apiUrl}/scrap/scrappedList`, {
          params: { page: 1, limit: 1000},
          headers,
        })
        setSingleData(data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('scrap list fetching fail:', error)
        toast.error(`Error: ${error?.message || error?.response?.data}`)
      }
    }
    featchData()
    
  },[])

  return (
    <>
      <PlainHeader toggleLoggedInState={toggleLoggedInState}/>
      <div className="create-chatbot-container">
        <div className="create-chatbot-file-wrapper">
          <div className="create-title">
            <h2>Data Source</h2>
          </div>

          <Tabs>
            <TabList className="create-chatbot-steps">
              <Tab className="create-chatbot-step-item">Links</Tab>
              <Tab className="create-chatbot-step-item">Files</Tab>
            </TabList>

            <TabPanel className="create-chatbot-content">
              <TrainLinks />
            </TabPanel>

            <TabPanel className="create-chatbot-content">
              <TrainFiles />
            </TabPanel>
            
              
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default UpdateChatbotFileScreen