import axios from 'axios'
import React, { useState } from 'react'
import { FiChevronRight, FiUpload } from 'react-icons/fi'
import { MdCloudUpload } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { apiUrl } from '../../Variables'
import ConfigBot from '../../components/ConfigBot'
import PlainHeader from '../../components/PlainHeader'
import ConfigColor from '../../components/botConfig/ConfigColor'

import tempImg from '../../images/not-found.png'


function ChatbotConfigScreen({toggleLoggedInState}) {

  const [uploadLoading, setUploadLoading] = useState(false)
  const [loading, setLoadLoading] = useState(false)
  const [logo, setLogo] = useState(null)
  const [getLinkIds, setGetLinkIds] = useState([])
  const [botId, setBotId] = useState('')

  const navigate = useNavigate(); 

  const [config, setConfig] = useState({
    botName: 'My Bot',
    welcomeMessage: 'Hi there! How can I help you?',
    placeholder: 'Ask me...',
    logo: tempImg,
    brandColor: '#009688',
    ...JSON.parse(localStorage.getItem('botInfo')),
    ...JSON.parse(localStorage.getItem('botConfig'))
  })
  // useEffect(() => {
  //   // Reset the scroll position to the top when the component mounts
  //   window.scrollTo(0, 0);
  // }, []);
  
  // file upload handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "pewhglsn")
    formData.append("cloud_name", "dzo2vmjdy")

    try {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, headers)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload', formData, headers)
      // curl https://api.cloudinary.com/v1_1/<CLOUD_NAME>/image/upload -X POST --data 'file=<FILE>&timestamp=<TIMESTAMP>&api_key=<API_KEY>&signature=<SIGNATURE>'
      setConfig({ ...config, logo: data?.url });
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  // config handler
  const botConfigHandler = async () => {
    try {
      const {data} = await axios.post(`${apiUrl}/botConfig/`)
    } catch (error) {
      console.log('Error:', error?.message || error?.response?.data)
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoadLoading(true)
    console.log(config)
    localStorage.setItem('botInfo', JSON.stringify({name: config?.botName, scrappedOrUpDataId: JSON.parse(localStorage.getItem('selectedLinks'))}))
    localStorage.setItem('botConfig', JSON.stringify(config))
    navigate(`/chatbot-prompt`)
  };

  console.log('config', config)

  return (
    <>
      <PlainHeader toggleLoggedInState={toggleLoggedInState}/>

      <div className="chatbot-config-wrapper">

        {/* configaration fields */}
        <div className="chatbot-config-fields">
          <div className="form-title">
            <h3>Design & configure your chatbot</h3>
            <p>Change your chabot yourself</p>
          </div>
          <form onSubmit={submitHandler}>
            {/* chatbot name */}
            <div className="form-group">
              <label htmlFor="">Chatbot name</label>
              <input 
                onChange={(e) => {
                  config.botName = e.target.value
                  setConfig({...config})
                }} 
                value={config?.botName}
                type="text" 
                placeholder='Chatbot name' 
              />
            </div>
            {/* welcome message */}
            <div className="form-group">
              <label htmlFor="">Welcome message</label>
              <textarea
                onChange={(e) => {
                  config.welcomeMessage = e.target.value
                  setConfig({...config})
                }} 
                value={config?.welcomeMessage}
               placeholder='Hi, How can I help you?' />
            </div>
            {/* placeholder name */}
            <div className="form-group">
              <label htmlFor="">Input placeholder name</label>
              <input 
                onChange={(e) => {
                  config.placeholder = e.target.value
                  setConfig({...config})
                }} 
                value={config?.placeholder}
                type="text" placeholder='Ask me anything' />
            </div>

            {/* upload photo */}
            <div className="config-upload-logo">
              <p className='uploaded-label'>Upload new file</p>
              <div className="chatbot-upload-image-wrap">
                <label>
                  {
                    uploadLoading && <p>Uploading...</p>
                  }
                  {
                    !config?.logo && (
                      <div className="chatbot-upload-content-wrap">
                        <FiUpload className='user-update-icon' />
                        <h3>Upload Logo</h3>
                        <p>Please upload only JPG, PNG, SVG or GIF file</p>
                        <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                      </div>
                    )
                  }
                  
                  {
                    config?.logo && (
                      <div className="user-profile-header-thumb create-group-logo">
                  
                        <img src={config?.logo && config?.logo || tempImg} alt="profile photo" />
                        <div className="user-update-profile create-user-profile-update-icon-wrapper">
                          <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                          <div className="user-update-profile">
                            <MdCloudUpload className='user-update-icon' />
                          </div>
                        </div>
                      </div>
                    )
                  }
                  
                </label>
              </div>
            </div>

            {/* color picker */}
            <ConfigColor config={config} setConfig={setConfig} />
          </form>
          
          {/* action button */}
          <div className="chatbot-action-wrap">
              <li className='chatbot-action-item'>Create chatbot</li>
              <Link onClick={submitHandler} to=''>
                <li className='chatbot-action-item active'> <span>{loading ? 'Loading...' : 'Next'}</span> <FiChevronRight /> </li>
              </Link>
          </div>
        </div>

        {/* chatbot */}
        <div className="chatbot-config-bot">
          <ConfigBot config={config} setConfig={setConfig} />
        </div>
      </div>

    </>
  )
}

export default ChatbotConfigScreen