import React from 'react';
import { FaRegLightbulb } from "react-icons/fa";
import { Link } from 'react-router-dom';

function BusinessGoalTips() {
  return (
    <>
      <div className="bg-white p-10 sm:pl-0 pl-6 sm:pt-10 pt-6 rounded-lg flex justify-start items-start flex-col max-w-[600px] gap-2 border-t border-gray-200 sm:border-t-0 sm:rounded-none">
          {/* <p className="absulate">X</p> */}
          <h2 className="text-xl font-semibold mb-2 flex justify-start gap-2 items-center"><FaRegLightbulb className='text-gray-500' /> <span className='font-semibold'>Examples & Tips</span></h2>
          <div className="space-y-4">

            <p className='text-left'>Include CTAs in your chatbot to guide users toward actions that benefit your business. A well-placed CTA encourages engagement and drives results.</p>

            <p className='text-left text-gray-700 !font-semibold'>Here are some examples:</p>
            <div className="bg-gray-100 p-4 rounded-md flex flex-col gap-2 justify-start">
              <p className='text-left'> <span className="text-gray-700 !font-semiBold">Increase sales: </span> Ask customers for their name, email, and phone number before ending the chat.</p>

              <p className='text-left'> <span className="text-gray-700 !font-semiBold">Schedule appointments: </span>  Provide a direct link to your booking page, e.g., <Link to="www.calendly.com/mylink">Calendly</Link>.</p>

              <p className='text-left'> <span className="text-gray-700 !font-semiBold">Promote events: </span> Direct users to your latest events page, e.g., Latest Events.</p>
            </div>
            <p className='text-left bg-green-50 p-4 rounded'> <span className="font-medium text-brand-color-bold">💡 Pro Tip: </span> Make your CTAs clear and link to valuable resources to boost user engagement.</p>
          </div>
        </div>
    </>
  )
}

export default BusinessGoalTips