import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'
import { apiUrl } from '../Variables'

function PaymentStatusScreen() {
  const {userId, planId, token, status} = useParams()
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
  console.log({userId, planId, token, status})

  const navigate = useNavigate()

  const handleNotify = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          }
        }
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        await axios.post(`${apiUrl}/user/marketPlaceSendNotifyMail`, {email: userInfo.email}, {headers}).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
        })
      } catch (error) {
        console.log(error)
      }
    // }
  };

  useEffect(() => {
    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const featchData = async () => {
      try {
        const {data} = await axios.post(`${apiUrl}/stripe/paymentSuccess`, {subscription_id: planId, user_id: userId, addon_success: status === 'addon-success'}, {headers})
        await axios.post(`${apiUrl}/user/token_by_id`, { userId }).then(async res => {
          console.log(res)
          setUserInfo(res.data)
          localStorage.setItem('userInfo', JSON.stringify(res.data))
        }).catch(err => {
          console.log(err)
        })
        // if (status === 'whatsapp-subscription-success') {
          await handleNotify()
        // }
        setTimeout(() => {navigate('/login', { replace: true })}, 2000)
      } catch (error) {
        console.log('scrap list fetching fail:', error)
      }
    }            
    if(userId && planId && token && (status === 'success' || status === 'addon-success')) {
      featchData()
    }
    
  },[userId, planId, token, status])
  return (
    <>
      <div className="payment-status-wrapper">
        {
          (status === 'success' || status === 'addon-success') ?  <form className='verify-fail-wrap payment-status-wrap'>
          <div className='form-title payment-status-title'>
            <AiFillCheckCircle />
            <h2 className='forgot-heading verify-fail-heading'> Payment Success! </h2>
            {/* <p>Your payment is successfull. You are automatically logged out for security purpose. Please login again.</p> */}
            <p>Your payment is successfull. Click the button below to go to your dashboard.</p>
          </div>
          
          <Link to="/login" className='payment-status-action'>
            <button className='form-button payement-btn'>
              Go to home!
            </button>
          </Link>
        </form> : <form className='payment-status-wrap'>
            <div className='form-title payment-status-title payment-status-fail-title'>
              <AiFillCheckCircle />
              <h2 className='forgot-heading verify-fail-heading'> Payment Failed! </h2>
              {/* <p>Your payment is rejected. You are automatically logged out for security purpose. Please login again.</p> */}
              <p>Your payment is rejected. Click the button below to go to your dashboard.</p>
            </div>
            
            <Link to="/login" className='payment-status-action'>
              <button className='form-button payement-btn payment-btn-fail'>
                Go to home!
              </button>
            </Link>
          </form>
        }
       
        </div>
    </>
  )
}

export default PaymentStatusScreen