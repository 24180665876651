'use client';

import { ArrowRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import translations from '../../context/Translations'
import { useLanguage } from '../../context/LanguageContext'

export default function CTASection() {
  const [isVisible, setIsVisible] = useState(true);

  const {lanCode} = useParams()
  const { language, setLanguage } = useLanguage();
  if (lanCode) {
    if (lanCode === 'en') {
      setLanguage('english');
    } else if (lanCode === 'ms') {
      setLanguage('malay');
    } else if (lanCode === "ind") {
      setLanguage('indonesia');
    }

  }
  const t = translations[language];

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return (
    <section className="w-full py-12 md:py-24 lg:py-24 bg-gradient-to-br from-bg-brand-color to-bg-brand-color-bold text-white overflow-hidden">
      <div className="container mx-auto">
        <div className=" bg-white/5 backdrop-blur-sm rounded-xl transition-all duration-1000 p-3 sm:p-10">
          <div className="flex flex-col justify-center text-center items-center gap-6">
            <div className="">
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-[35px] !sm:leading-[50px] sm:leading-[80px] max-w-5xl text-center mx-auto text-white pb-3">
                {t?.ctaSection?.title}
              </h2>
              <p className="max-w-[900px] text-zinc-200 md:text-xl dark:text-zinc-100 mx-auto">
                {t?.ctaSection?.subTitle}
              </p>
            </div>
            <div className="w-full max-w-[320px] mx-auto">
              <Link to="/register">
                <button 
                  className="w-full text-lg font-semibold text-brand-color bg-white hover:bg-emerald-100 transition-colors duration-300 py-4 rounded-full shadow-lg hover:shadow-xl flex justify-center items-center gap-[5px] group"
                >
                  <span>{t?.ctaSection?.button}</span>
                  <ArrowRight className="ml-2 h-6 w-6 group-hover:translate-x-1 text-brand-color" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}