import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import marketplace from '../../../images/icons/gc.png'


function GoogleCalendarIntegration() {
  const [events, setEvents] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  return (
    <div className="marketplace-item">
      {
        userInfo?.utilityData?.grt && <div className="marketplace-status">Active</div>
      }
      <div className="marketplace-item-thumb">
        <img src={marketplace} alt="marketplace icon" />
      </div>
      <h2>Google Calendar Integration</h2>
      <Link to="/google-calendar-details">
        <button className="w-fit mx-auto py-2 px-4 bg-brand-color text-white rounded-lg hover:bg-brand-color-bold transition-colors font-medium">View More</button>
      </Link>
    </div>
  );
}

export default GoogleCalendarIntegration;
