import { Tabs } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { BsAndroid2, BsGlobe2 } from 'react-icons/bs';
import { IoColorPaletteSharp, IoStorefrontSharp } from 'react-icons/io5';
import { TbPrompt } from "react-icons/tb";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { apiUrl } from '../Variables';
import Layout from '../components/Layout';
import Breadcrumb from '../components/globals/Breadcrumb';
import UpdateAppearanceSteps from '../components/update-chatbot/UpdateAppearanceSteps';
import UpdateLanguageSteps from '../components/update-chatbot/UpdateLanguageSteps';
import UpdateMarketplaceSteps from '../components/update-chatbot/UpdateMarketplaceSteps';
import UpdatePreviewSteps from '../components/update-chatbot/UpdatePreviewSteps';
import UpdatePromptSteps from '../components/update-chatbot/UpdatePromptSteps';


function UpdateChatbotScreen({toggleLoggedInState}) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const sTab = searchParams.get('tab')
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
  const [config, setConfig] = useState(null)
  const [wholeConfig, setWholeConfig] = useState({})
  const [promptLists, setPromptLists] = useState([]);
  const [promptObj, setPromptObj] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchGroup = async () => {
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      try {
        const { data } = await axios.get(`${apiUrl}/botConfig/${id}`, { headers });
        console.log('botconfig', data)
        setConfig(data[0]?.configData);
        setWholeConfig(data[0]);
      } catch (error) {
        console.log('Bot config fetch error:', error);
      }

      await axios.get(`${apiUrl}/botMenu/${id}`, {headers}).then(res => {
        console.log(res)
        setPromptLists(res.data[0].menues)
        setPromptObj(res.data[0])
      }).catch(err => {
        console.log(err)
      })
    };
    fetchGroup();
  }, [id]);

  const onChange = (key) => {
    console.log(key);
  };

  // update the config
  const submitHandler = async () => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    try {
      setCreateLoading(true)
      const { data } = await axios.put(`${apiUrl}/botConfig/${wholeConfig?._id}`, {_id: wholeConfig?._id, configData: config, chatbotId: id}, { headers });
      await axios.put(`${apiUrl}/chatbot/${id}`, {_id: id, name: config.botName}, { headers });
      await axios.put(`${apiUrl}/botMenu/${promptObj._id}`, {...promptObj, menues: promptLists}, { headers });
      setCreateLoading(false)
      console.log('response data:', data)
      toast.success('Bot config update successfully!')
      navigate('/chatbot-list')
    } catch (error) {
      setCreateLoading(false)
      console.error('Error to bot config update:', error);
      toast.error(`Failed: ${error?.message || error?.response?.data}`)
    }
  };

  const items = [
    {
      key: '1',
      label: 'Preview',
      icon: <BsAndroid2 />,
      children: <UpdatePreviewSteps config={config} setConfig={setConfig} promptLists={promptLists}/>,
    },
    {
      key: '2',
      label: 'Appearance',
      icon: <IoColorPaletteSharp />,
      children: <UpdateAppearanceSteps config={config} setConfig={setConfig}/>,
    },
    {
      key: '3',
      label: 'Prompts',
      icon: <TbPrompt />,
      children: <UpdatePromptSteps promptLists={promptLists} setPromptLists={setPromptLists} config={config}/>,
    },
    {
      key: '4',
      label: 'Language',
      icon: <BsGlobe2 />,
      children: <UpdateLanguageSteps config={config} setConfig={setConfig}/>,
    },
    {
      key: '5',
      label: 'Marketplace',
      icon: <IoStorefrontSharp />,
      children: <UpdateMarketplaceSteps config={config} setConfig={setConfig}/>,
    },
    // {
    //   key: '6',
    //   label: 'Business Goals',
    //   icon: <LuTarget />,
    //   children: <UpdateBusinessGoalSteps config={config} setConfig={setConfig}/>,
    // },
  ];
  
  return (
    <Layout toggleLoggedInState={toggleLoggedInState}>
      <>
        <Breadcrumb steps={true} stepLabel="All Chatbots" stepUrl="/chatbot-list" title="Edit Chatbot" />
        <div className="create-title w-full">
          <h2>Chatbot Configuration</h2>
          <p>Make the chatbot your own.</p>
          <p></p>
          <button onClick={submitHandler} className="form-button update-change-btn">{createLoading ? 'Updating...' : 'Update'}</button>
        </div>
        <Tabs 
          defaultActiveKey={sTab || "1" }
          onChange={onChange} 
          tabPosition="left"
          className='update-chatbot-container'
        >
          {items.map(item => (
            <Tabs.TabPane
              className='update-tab-item'
              tab={
                <span>
                  {item.icon}
                  <span>{item.label}</span>
                </span>
              }
              key={item.key}
            >
              {item.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </>
    </Layout>
  )
}

export default UpdateChatbotScreen;
