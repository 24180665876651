
import React, { useEffect, useState } from 'react';
import HeroSection from '../../components/blog/HeroSection';
import LatestPostSection from '../../components/blog/LatestPostSection';
import FooterV2 from '../../components/landing-page/FooterV2';
import HeaderMenu from '../../components/landing-page/HeaderMenu';

function BlogLandingScreen() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://gptsaas.aceva.com.my/api/blogs');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPosts(data?.data || []);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError('Unable to load blog posts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <>
        <HeaderMenu />
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-brand-color"></div>
        </div>
        <FooterV2 />
      </>
    );
  }

  if (error || !posts.length) {
    return (
      <>
        <HeaderMenu />
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
          <div className="text-center p-8 max-w-2xl mx-auto">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              {error || 'No blog posts found'}
            </h2>
            <p className="text-gray-600 mb-8">
              {error ? 'Please try refreshing the page or check back later.' : 'Check back soon for new content!'}
            </p>
            <button 
              onClick={() => window.location.reload()} 
              className="bg-brand-color text-white px-6 py-2 rounded-lg hover:bg-brand-color-bold transition-colors"
            >
              Refresh Page
            </button>
          </div>
        </div>
        <FooterV2 />
      </>
    );
  }

  return (
    <>
      <HeaderMenu />
      <section className='!bg-gray-50 py-12'>
        {/* <HeroSection posts={posts} /> */}
        <LatestPostSection posts={posts} />
      </section>
      <FooterV2 />
    </>
  );
}

export default BlogLandingScreen;