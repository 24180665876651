import { useState } from "react";

import fallback from '../../images/accordion/fallback.webp';

export default function AccordionSection({t}) {
  const [activeIndex, setActiveIndex] = useState(0); // Default open is the first item

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="bg-white py-10 sm:py-20 xs:py-16">
      {/* bg-[#f6f4f1] */}
      <div className="container mx-auto">
        {/* Left Column */}
        <div className="space-y-8">
          <div className="space-y-6 mb-16 mx-auto">
            <p className="text-base font-medium uppercase tracking-widest text-brand-color">
              {t?.accordionSection?.subTitle}
            </p>
            <h2 className="text-3xl font-semibold leading-tight md:text-5xl lg:text-5xl mx-auto">
            {t?.accordionSection?.title}
            </h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 w-full mx-auto">
            <div className="space-y-3 sm:space-y-4">
              {/* Accordion Items */}
              {t?.accordionSection?.accordions?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => toggleAccordion(index)}
                  className={`rounded-2xl p-4 sm:p-8 shadow-sm transition-shadow hover:shadow-md border ${
                    activeIndex === index ? "border-l-4 border-brand-color-bold" : ""
                  }`}
                  style={{
                    backgroundColor:
                      activeIndex === index ? "brand-color" : "white",
                  }}
                >
                  <button
                    className="flex w-full items-center justify-between"
                  >
                    <h2 className="text-lg sm:text-2xl font-medium text-left">{item.title}</h2>
                  </button>
                  {activeIndex === index && (
                    <p className="mt-4 text-gray-700">{item.content}</p>
                  )}
                </div>
              ))}
            </div>
            {/* Right Column - Dynamic Image */}
            <div className="relative aspect-square overflow-hidden rounded-3xl lg:aspect-auto mx-auto">
              <img
                src={t?.accordionSection?.accordions[activeIndex]?.image || fallback}
                alt={t?.accordionSection?.accordions[activeIndex]?.title || "Default"}
                className="h-[680px] w-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
