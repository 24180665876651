
import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaGlobe } from 'react-icons/fa';
import FooterV2 from '../../components/landing-page/FooterV2';
import HeaderMenu from '../../components/landing-page/HeaderMenu';

/**
 * AuthorScreen Component
 * Displays an author's profile and their latest blog posts
 * Uses Next.js Image component for optimized image loading
 * Fetches author data and posts from API
 */
function AuthorScreen() {
  const { slug } = useParams();
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        const response = await fetch(`https://gptsaas.aceva.com.my/api/authors/slug/${slug}`);
        const data = await response.json();
        setAuthor(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching author data:', error);
        setLoading(false);
      }
    };

    if (slug) {
      fetchAuthor();
    }
  }, [slug]);

  console.log("author", author);

  if (loading) {
    return (
      <>
        <HeaderMenu />
        <section className='bg-gray-50 py-12'>
          <div className='container mx-auto px-4'>
            <div className='grid grid-cols-[2fr_8fr] w-full gap-8 mb-12'>
              <div className='w-full relative h-[100px] bg-gray-200 animate-pulse rounded-lg' />
              <div className='w-full space-y-4'>
                <div className='w-48 h-8 bg-gray-200 animate-pulse rounded' />
                <div className='space-y-2'>
                  <div className='w-full h-4 bg-gray-200 animate-pulse rounded' />
                  <div className='w-full h-4 bg-gray-200 animate-pulse rounded' />
                  <div className='w-3/4 h-4 bg-gray-200 animate-pulse rounded' />
                </div>
              </div>
            </div>
            <div className='w-64 h-8 bg-gray-200 animate-pulse rounded mb-8' />
            <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
              {[...Array(6)].map((_, index) => (
                <div key={index} className='bg-white rounded-lg shadow-lg overflow-hidden'>
                  <div className='h-48 bg-gray-200 animate-pulse' />
                  <div className='p-6 space-y-4'>
                    <div className='w-24 h-6 bg-gray-200 animate-pulse rounded-full' />
                    <div className='w-3/4 h-6 bg-gray-200 animate-pulse rounded' />
                    <div className='space-y-2'>
                      <div className='w-full h-4 bg-gray-200 animate-pulse rounded' />
                      <div className='w-full h-4 bg-gray-200 animate-pulse rounded' />
                    </div>
                    <div className='w-32 h-4 bg-gray-200 animate-pulse rounded' />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <FooterV2 />
      </>
    );
  }

  return (
    <>
      <HeaderMenu />
      <section className='bg-gray-50 py-12'>
        <div className='container mx-auto px-4'>
          <div className='grid grid-cols-[2fr_8fr] w-full gap-8 mb-12'>
            <div className='w-full relative h-[100px]'>
              <img
                src={author?.avatar || '/images/aceva.png'}
                alt={`${author?.name}'s profile`}
                fill
                className='object-contain rounded-lg'
              />
            </div>
            <div className='w-full'>
              <h1 className='text-3xl font-bold mb-2 text-gray-900'>
                {author?.name}
              </h1>
              <p className='text-gray-600 mb-4 text-base leading-7'>
                {author?.biography}
              </p>
              <ul className='flex gap-4 items-center'>
                {author?.website && (
                  <li>
                    <a to={author.website} target="_blank" rel="noopener noreferrer" className='text-2xl text-brand-color hover:text-brand-color-bold'>
                      <FaGlobe />
                    </a>
                  </li>
                )}
                {author?.facebookProfile && (
                  <li>
                    <a to={author.facebookProfile} target="_blank" rel="noopener noreferrer" className='text-2xl text-brand-color hover:text-brand-color-bold'>
                      <FaFacebook />
                    </a>
                  </li>
                )}
                {author?.twitterProfile && (
                  <li>
                    <a to={author.twitterProfile} target="_blank" rel="noopener noreferrer" className='text-2xl text-brand-color hover:text-brand-color-bold'>
                      <FaTwitter />
                    </a>
                  </li>
                )}
                {author?.linkedInProfile && (
                  <li>
                    <a to={author.linkedInProfile} target="_blank" rel="noopener noreferrer" className='text-2xl text-brand-color hover:text-brand-color-bold'>
                      <FaLinkedin />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <h2 className='text-2xl font-bold mb-8 text-gray-900'>
            Latest Posts from - <span className='text-brand-color'>{author?.name}</span>
          </h2>

          <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
            {author?.blogs?.map((post) => (
              <div
                key={post.id}
                className='bg-white rounded-lg shadow-lg overflow-hidden'
              >
                <div className='relative h-48'>
                  <img
                    src={post.featuredImage || '/images/support/calendar.png'}
                    alt={post.title}
                    fill
                    className='object-cover'
                  />
                </div>
                <div className='p-6'>
                  {/* <Link
                    to={`/category/${post.category?.slug}`}
                    className='inline-block px-3 py-1 mb-4 bg-green-50 text-brand-color-bold font-medium rounded-full hover:bg-green-100'
                  >
                    {post.category?.name}
                  </Link> */}
                  <h2 className='text-xl font-semibold mb-3 hover:text-brand-color'>
                    <Link to={`/blog/${post.slug}`}>
                      {post.title}
                    </Link>
                  </h2>
                  <div 
                    className='text-gray-600 mb-4'
                    dangerouslySetInnerHTML={{__html: post.excerpt || post.content?.substring(0, 150) + '...'}}
                  />
                  <div className='flex items-center justify-start gap-4 text-sm'>
                  <span className='text-gray-500 capitalize'>
                      {new Date(post.createdAt).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                      }).toLowerCase()}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <FooterV2 />
    </>
  );
}

export default AuthorScreen;
