import React from 'react'

function LiveDemoSection({t}) {
  return (
    <section id='live-demo' className="live-demo-section !bg-gray-50" style={{position: 'relative', height:'800px'}}>
      <div className="landing-container">
        <div className="live-demo-wrapper">
          <div className="landing-title">
            <h2>{t?.chatbotSection?.title}</h2>
            <p>{t?.chatbotSection?.subTitle}</p>
          </div>
          
          {/* iframe bot wrapper */}
          <div className='site-bot-iframe-wrapper' >

            {/* new header for acevagpt bot */}
            {/* <div className="bot-config-header chatbot-iframe-widget-header overlay-header">
              <div className="chatbot-widget-agent-precence chatbot-iframe-precence">
                <div className="chatbot-widget-header-left chatbot-iframe-left">
                  <div className="chatbot-recipient-avatar">
                    <img src={logo} alt="logo" />
                      <div className="online-status"></div>
                  </div>
                  <div className="chatbot-widget-recipient-details">
                    {
                      <p>Powered by 
                      <Link to="https://www.aceva.com.my/" target="_blank" className='company-name'> ACEVA GPT </Link>
                    </p>
                    }
                    <h3>
                      ACEVA GPT
                    </h3>
                  </div>
                </div>
              </div>
            </div> */}
            
            <iframe title='demo bot' style={{height:'500px', width:'450px', borderRadius: "10px", border: "1px solid #d3d3d33d"}} src="http://localhost:3000/botIframe/651e6d7e21fe7b6c2f17c435/651ea15c13ff0d95a4dfe3d0?open=open"></iframe>

          </div>
        </div>
      </div>
    </section>
  )
}

export default LiveDemoSection