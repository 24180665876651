import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { apiUrl } from '../Variables';
import Logo from '../images/aceva.png';

function VerifySuccessScreen() {
  const {id, token} = useParams();
  const [loading, setLoading] = useState('true')


  useEffect(() => {
     // Add headers to include JWT token
     const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios.get(`${apiUrl}/user/${id}`, { headers }).then(async res => {
      console.log(res)
      if (res.data?.email_verified) {
        setLoading('false')
        return 0
      }
      await axios.put(`${apiUrl}/user/${id}`, {...res.data, email_verified: true}, { headers }).then(res => {
        setLoading('false')
      }).catch(err => {
        setLoading('error')
        console.log('update user err', err)
      })
       //mailer lite start
       await axios.post(`https://connect.mailerlite.com/api/subscribers`, {
        email: res.data.email,
        fields: {
          name: "Your name",
          last_name: "Ser name"
        },
        "groups": [
            "106144523779835742"
        ]
      }, { headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNjJlNjM5ZGJiZjYxZDYxODE0NjRlMzk4ZWY5NGRlNmE5OTU5ZDZjNTliYTBiYTg1OWE4YzJmY2FmYjYyZWEwOGUyNGJjY2U2MTZjM2Y1M2UiLCJpYXQiOjE3MDIzNjk5NTkuNzEzNTQ1LCJuYmYiOjE3MDIzNjk5NTkuNzEzNTQ4LCJleHAiOjQ4NTgwNDM1NTkuNzA4NzkyLCJzdWIiOiI0NDM0NjUiLCJzY29wZXMiOltdfQ.TQOIfJk4-UR7eVPeTkbXz2DVjCxczuWaTSBo1LC75UBFK8NjIDncGpA_YVDDSdpWTTyHYeAx2pMnO6HDciv_Nsy1rLAzCORXXmKldLGq_tDJusWip21M9xcAtT2Xf9RXQ-UXQluPczpxmgr419KonjkZDL__MW-lc5ERhUi8rTmfT28bM9887yAOehrMTnCAKNH7OoU_zN3q3FA5e_VTvCvYFA2x-fGg2JOhDGMUF77QYZR_aNebzjDNLG2Y4jaPQTztPi6hOXzTjjlvYMILhhefHg-2567p0RlDdvCXeQyIM9hUndf4Ww3yIL_kV6Riy8tmUZ5WGkZ4_LP1M_oWv7UHChrCZMAl1YH6I8tFNcOhzGiLF_syYhT-xDtRrYLDH9tQYlVlxoSdTgN97vigYqq1ZypP1lMI81ksIYU1YZmR6RGLMpinsf8jOSe0_jLRMy3bwFv17CPWL801ZdBMbMMyAF-68AgrBuQOEK0hO3oM_UIbQVYaWDR7UTcIdIMgJPz8n4pUk2cWPHN-eAcJpsdZ8c_PLKLRYcGt3ldC9bguWgoi0Yk-9gqjKP3019IMasnnREdeK4SFkXqnXglcFjXj26NYwlwHj6yuwYvE7UxN18Eswli01OUsS3LgzwV_hpAnlEsFxHsbg8gl7OpOKOpFO7CgUR77O9jFAtnHplE'
      }}).then(res => {
        console.log('mailer lite', res)
      }).catch(err => {
        console.log('update user err', err)
      })
      //mailer lite end
    }).catch(err => {
      setLoading('error')
      console.log('get user err', err)
    })
  }, []);
  return (
    <>
      <div className="register-container middle-container center-container">
        <div className="register-wrapper center-wrapper">
          {
            loading === 'true' ? <form>
            <div className='form-title'>
              <h2 className='forgot-heading'> Congratulations, you are verified! </h2>
            </div>
          </form> : loading === 'false' ? <form>
                <Link to="/">
                  <div className="single-logo center-logo-wrap">
                    <img src={Logo} alt="logo" />
                  </div>
                </Link>
              <div className='form-title'>
                {/* <h2 className='forgot-heading'> Verification Successfull </h2> */}
              </div>
                <p> Lets get you started!</p>
                
                <Link to="/login">
                  <button type='submit' className='form-button verify-btn'>
                    Login to ACEVA
                  </button>
                </Link>
            </form> : loading === 'error' ? <form className='verify-fail-wrap'>
              <div className='form-title'>
                <h2 className='forgot-heading verify-fail-heading'> Verification Failed! </h2>
              </div>
                <p>Your account is not verified! please check your email address. <BsFillExclamationCircleFill className='verify-svg verify-fail-svg' /></p>
                
                <Link to="/register">
                  <button type='submit' className='form-button verify-btn verify-fail-btn'>
                    Sign up
                  </button>
                </Link>
            </form> : ''
          }
        </div>
      </div>
    </>
  )
}

export default VerifySuccessScreen