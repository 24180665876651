import { Progress } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../Variables';
import ConfigBot from '../../components/ConfigBot';

function FinalPreviewStep() {
  const [selectedItems, setSelectedItems] = useState(JSON.parse(localStorage.getItem('selectedLinks')) || []);
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [config, setConfig] = useState({...JSON.parse(localStorage.getItem('botConfig'))})
  const [botInfo, setBotInfo] = useState({...JSON.parse(localStorage.getItem('botInfo'))})
  const [promptLists, setPromptLists] = useState(JSON.parse(localStorage.getItem('menuList')) || []);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(10);
  const [logo, setLogo] = useState(null);
  const [showPhoneAlert, setShowPhoneAlert] = useState(false);
  const [getLinkIds, setGetLinkIds] = useState([]);
  const [botId, setBotId] = useState('');

  const navigate = useNavigate();

  const updateProgress = (value) => {
    setProgress(value);
  };

  const finalUpdateBot = async (scrappedIds, headers, createdBot) => {
    try {
      const chatbotResponse = await axios.put(`${apiUrl}/chatbot/${createdBot._id}`, {...createdBot, scrappedOrUpDataId: scrappedIds}, { headers })
      updateProgress(75);

      await axios.post(`${apiUrl}/botConfig`, {configData: config, chatbotId: chatbotResponse?.data?._id}, { headers });
      updateProgress(100);

      const dataObj = {chatbotId: chatbotResponse?.data?._id, menues: promptLists};
      await axios.post(`${apiUrl}/botMenu`, dataObj, {headers});
      updateProgress(100);
      
      localStorage.removeItem("botConfig")
      localStorage.removeItem("botInfo")
      localStorage.removeItem("menuList")
      localStorage.removeItem("selectedLinks")
      toast.success('Chatbot created successfully.');
      navigate('/chatbot-list');
    } catch (error) {
      console.error('Submission error', error);
      toast.error("Failed to create the chatbot.");
      setLoading(false);
    }
  };

  const createBot = async (headers) => {
    const chatbotResponse = await axios.post(`${apiUrl}/chatbot`, {...botInfo, scrappedOrUpDataId: []}, { headers });
    return chatbotResponse.data
  }

  const deleteBot = async (id, headers) => {
    await axios.delete(`${apiUrl}/chatbot/${id}`, {headers})
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!userInfo.phone) {
    //   setShowPhoneAlert(true);
    //   return;
    // }
    setLoading(true);
    setUploadLoading(true);
    updateProgress(0);
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    let createdBot = null
    try {
      createdBot = await createBot(headers)
      updateProgress(15);
      const upUrls = []
      let scrappedIds = []
      for (const contents of selectedItems) {
        if (contents?.upFileName) {
            const res = await axios.post(`${apiUrl}/scrap/storePdfData`, {...contents, botId: createdBot._id}, { headers });
            scrappedIds = [...scrappedIds, ...res.data.scrappedIds]
        } else {
            upUrls.push(contents)
        }
      }
      updateProgress(20);
      if (upUrls.length > 0) {
        const res1 = await axios.post(`${apiUrl}/scrap/scrapPages`, {page_urls: upUrls, botId: createdBot._id}, { headers });
        scrappedIds = [...scrappedIds, ...res1.data.scrappedIds]
      }
      updateProgress(50);
      await finalUpdateBot(scrappedIds, headers, createdBot);
    } catch (err) {
      toast.error("We regret to inform you that the website you are attempting to scrape may not be accessible for scraping. This could be due to one of the following reasons:\n \n1. A CAPTCHA or other bot-detection mechanism was detected\n2. The page content could not be parsed successfully\n3. The scraping request limit has been reached\n4. An error occurred while loading the page. \n\nPlease verify the URL or try again later. This resulting in the failure to create the chatbot.");
      if (createBot) {
        deleteBot(createdBot._id, headers)
      }
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-50 p-4 md:p-8">
      {showPhoneAlert && (
        <div className="mb-6 bg-yellow-100 p-4 rounded-lg">
          <p className="text-yellow-800">Please update your phone number to continue using your chatbot.</p>
          <a href="/settings" className="text-blue-600 hover:text-blue-800 underline">Update your phone number</a>
        </div>
      )}
      
      <div className="bg-white rounded-xl overflow-hidden">
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-1/2 p-4 md:p-6">
            <ConfigBot config={config} setConfig={setConfig} promptLists={promptLists} />
          </div>
          
          <div className="w-full lg:w-1/2 p-4 md:p-6 bg-gray-50 text-center flex flex-col justify-center items-center">
            <h2 className="text-xl md:text-2xl font-bold mb-3 text-gray-800">Everything looks good?</h2>
            <p className="text-sm md:text-base text-gray-600 mb-4 md:mb-8">
              Publish your chatbot now.<br />
              Don't worry, you can still edit your chatbot later.
            </p>

            <div className="w-full max-w-md mx-auto px-2">
              {loading ? (
                <Progress percent={progress} className="mb-4" />
              ) : (
                <button
                  onClick={handleSubmit}
                  className="w-fit bg-brand-color text-white py-2 px-6 rounded-lg hover:bg-brand-color-bold transition-colors duration-200"
                >
                  Publish Your Chatbot
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinalPreviewStep;
