'use client';

import React from 'react';
import { Link } from 'react-router-dom';

/**
 * HeroSection Component
 * Displays a featured blog post section with image, title and content
 * Uses Next.js components for optimized routing and image loading
 * Fetches blog data from API and handles the specific response format
 */
function HeroSection({ posts }) {
  const featuredPost = posts[0];

  if (!featuredPost) {
    return null;
  }

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row gap-8 items-center bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="lg:w-1/2 relative h-auto sm:h-[400px]">
            <img 
              src={featuredPost.featuredImage || "/images/support/calendar.png"}
              alt={featuredPost.title}
              fill
              className="object-cover"
              priority
              sizes="(max-width: 1024px) 100vw, 50vw"
            />
          </div>
          <div className="lg:w-1/2 p-4 sm:p-8">
            <Link 
              className="inline-block px-3 py-1 mb-4 text-sm font-semibold text-brand-color bg-green-50 rounded-full" 
              to={`/category/${featuredPost.category?.slug}`}
            >
              {featuredPost.category?.name}
            </Link>
            <h2 className="mb-4">
              <Link 
                to={`/blog/${featuredPost?.slug}`}
                className="text-3xl leading-[35px] sm:leading-[50px] font-bold text-gray-900 hover:text-brand-color transition-colors duration-200"
              >
                {featuredPost.title}
              </Link>
            </h2>
            <div className="mb-6 text-gray-600 leading-relaxed" dangerouslySetInnerHTML={{__html: featuredPost.content?.substring(0, 200) + '...'}} />
            <div className="flex items-center justify-between text-sm text-gray-500">
              <Link 
                className="font-medium text-brand-color hover:text-brand-color-bold"
                to={`/author/${featuredPost.author?.slug}`}
              >
                {featuredPost.author?.name}
              </Link>
              {featuredPost.videoURL && (
                <Link
                  to={featuredPost.videoURL}
                  className="text-brand-color hover:text-brand-color-bold"
                  target="_blank"
                >
                  Watch Video
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;