import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import HeaderMenu from '../components/landing-page/HeaderMenu'

import AccordionSection from '../components/landing-page/AccordionSection'
import CTASection from '../components/landing-page/CTASection'
import FAQSection from '../components/landing-page/FAQSection'
import FooterV2 from '../components/landing-page/FooterV2'
import HeroSectionV3 from '../components/landing-page/HeroSectionV3'
import IntegrationNewVersion from '../components/landing-page/IntegrationNewVersion'
import PricingSection from '../components/landing-page/PricingSection'
import TextSection from '../components/landing-page/TextSection'
import { useLanguage } from '../context/LanguageContext'
import translations from '../context/Translations'
import benefits from '../images/section/benefits.png'
import features from '../images/section/features.png'
import video from '../videos/landing-video.mp4'
import LeftSideFlexSection from '../components/landing-page/LeftSideFlexSection'

function LandingPageScreen() {
  const {lanCode} = useParams()
  const { language, setLanguage } = useLanguage();
  if (lanCode) {
    if (lanCode === 'en') {
      setLanguage('english');
    } else if (lanCode === 'ms') {
      setLanguage('malay');
    } else if (lanCode === "ind") {
      setLanguage('indonesia');
    }

  }
  const t = translations[language];

  const [openItemIndex, setOpenItemIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (index === openItemIndex) {
      // Clicking on the open item should close it
      setOpenItemIndex(null);
    } else {
      // Clicking on a closed item should open it and close others
      setOpenItemIndex(index);
    }
  };

  return (
    <>
    
      <Helmet>
        <title>Home | Aceva GPT</title>
      </Helmet>
      <div className="landing-page-canvas">
        
        <HeaderMenu t={t} />

        {/* sections */}
        <HeroSectionV3 t={t} />
        {/* <HeroSectionV2 t={t} /> */}
        {/* <NewHeroSection t={t} /> */}

        <TextSection t={t} />
        <AccordionSection t={t} />

        {/* features */}
        <section className=''>
          <div className="container py-4 sm:py-52">
                    {/* Header */}
                    <div className="flex flex-col md:flex-row justify-between items-center mb-8 gap-4 sm:gap-8 sm:mb-28">
                      
                      {/* title */}
                      <div className="flex items-center gap-2 text-2xl sm:text-5xl leading-[40px] !sm:leading-[60px] font-bold w-full sm:w-[60%]">
                        {
                          // if array, then map
                          t?.titleDetailSection?.title && Array.isArray(t?.titleDetailSection?.title) ?
                          <div className="flex flex-col">
                            {t?.titleDetailSection?.title.map((title, index) => (
                              <React.Fragment key={index}>
                                <span>{title}</span>
                              </React.Fragment>
                            ))}
                          </div>
                          :
                          <span>{t?.titleDetailSection?.title}</span>
                        }
                      </div>

                      {/* sub title and details */}
                      <div className="space-y-3 md:w-[40%]">
                        <p className="text-gray-600">
                        {t?.titleDetailSection?.subTitle}
                        </p>
                        <Link to="https://aceva.com.my/blog/the-impact-of-ai-chatbots-on-business-growth-research-based-analysis">
                        <button 
                          className="px-4 py-2 mt-2 rounded-lg text-brand-color hover:text-brand-color-bold transition-colors" style={{border: '1px solid #009688'}}
                        >
                          {t?.titleDetailSection?.button}
                        </button>
                        </Link>
                      </div>
                    </div>

            <img className='w-full h-auto py-10 sm:py-20 xs:py-16' src={features} alt="features" />
            
          </div>
        </section>

        {/* benefits */}
        <section>
          <div className="container">
            <h2 className='text-4xl md:text-5xl font-bold mb-28 leading-[45px] !sm:leading-[50px] sm:leading-[80px] max-w-5xl text-center mx-auto text-gray-800'>{t?.singleTitle}</h2>
            <img className='w-full h-auto pb-20 rounded-sm' src={benefits} alt="benefits" />
          </div>
        </section>

        {/* <CustomerStorieSection /> */}
        {/* <CustomerStorieSectionV2 /> */}

        <IntegrationNewVersion t={t} />

        {/* <section className='py-16'>
          <div className="container">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 text-gray-800">{t?.landingPageVideoTitle}</h2>

            <video src={video} className='w-full h-[570px] rounded-lg' controls></video>
          </div>
        </section> */}

        {/* <FeatureSection /> */}

        {/* marquee */}
        {/* <PerformanceStats t={t} /> */}
        {/* <MarqueeSection t={t} /> */}

        {/* state section */}
        {/* <ChatbotCreateSteps t={t} /> */}
        {/* <StatSection t={t} /> */}

        {/* process grid */}
        {/* <ProcessSection /> */}

        {/* left side flex section */}
        {/* <LeftSideFlexSectionV2 t={t} /> */}
        <LeftSideFlexSection t={t} />

        {/* integration section */}
        {/* <IntegrateSectionV2 t={t} /> */}
        <section className='py-28' style={{background: "#f3f4f6"}}>
        <PricingSection t={t} />
        </section>

        {/* testimonial */}
        {/* <TestimonialSection t={t} /> */}

        <FAQSection t={t} />
        <CTASection t={t} />

        {/* new foolter */}
        <FooterV2 t={t} />

      </div>
    </>
  )
}

export default LandingPageScreen