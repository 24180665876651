import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/aceva.png';
function VerifyUserScreen() {
  return (
    <>
      <div className="register-container middle-container center-container">
        <div className="register-wrapper center-wrapper">
            <form>
                <Link to="/">
                  <div className="single-logo center-logo-wrap">
                    <img src={Logo} alt="logo" />
                  </div>
                </Link>
              <div className='form-title'>
                <h2 className='forgot-heading'>Almost there!</h2>
              </div>
                <p>A confirmation email has been sent to your inbox.</p>
                <Link to="/login">
                  <button type='button' className='form-button center-button'>
                    Return to Login
                  </button>
                </Link>
            </form>
        </div>
      </div>
    </>
  )
}

export default VerifyUserScreen