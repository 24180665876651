import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import BusinessGoalTips from '../components/create-bot-steps/BusinessGoalTips';
import Breadcrumb from '../components/globals/Breadcrumb';

export default function ChatbotTypeScreen() {
  return (
    <Layout>
      <div>
        <Breadcrumb title="Chatbot types" steps={false} stepLabel="Business Goal" stepUrl="/setup-business-goal" />
      </div>
    <div className="flex sm:flex-row flex-col gap-5 border border-gray-200 rounded my-2 sm:my-6 mt-2 sm:mt-10">
      <div className="flex justify-center items-start border-r border-gray-200">
        <div className="bg-white p-6 w-full pt-4">
          <h2 className="text-2xl font-bold mb-2 text-left">Create your AI Chatbot</h2>
          
          <h3 className="text-lg text-gray-800 font-semibold mb-2 text-left">Your Goals Drive Our Design!</h3>
          
          <p className="mb-6 text-gray-600 text-left">
            Great! Based on your business goals, we'll guide you through creating a
            customized chatbot that fits your needs.
          </p>
          
          <p className="font-semibold mb-4 text-gray-900">Choose your platform:</p>
          
          <div className="space-y-4 w-full">
            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-3 sm:space-y-0 sm:space-x-4">
              <Link to="/create-chatbot-steps" className="w-full sm:w-auto">
                <button className="w-full sm:w-auto bg-brand-color text-white px-4 py-3 rounded-[10px] font-semibold text-sm whitespace-nowrap hover:bg-brand-color-bold transition duration-300 min-w-[160px]">
                  Website Bot
                </button>
              </Link>
              <p className="text-sm text-gray-600">
                Engage visitors directly on your website and turn them into loyal customers.
              </p>
            </div>
            
            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-3 sm:space-y-0 sm:space-x-4">
              <Link to="/whatsapp-integration" className="w-full sm:w-auto">
                <button className="w-full sm:w-auto bg-brand-color text-white px-4 py-3 rounded-[10px] font-semibold text-sm whitespace-nowrap hover:bg-brand-color-bold transition duration-300 min-w-[160px]">
                  Whatsapp Bot
                </button>
              </Link>
              <p className="text-sm text-gray-600">
                Connect with your audience instantly on one of the most popular messaging platforms.
              </p>
            </div>
          </div>
        </div>
      </div>
      <BusinessGoalTips />
    </div>
    </Layout>
  )
}