import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style/Dashboard.css';
import './style/Responsive.css';
import { Toaster } from 'react-hot-toast';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LanguageProvider } from './context/LanguageContext';

// Define custom toast styles
const customToastStyles = {
  success: {
    iconTheme: {
      primary: '#009688', // Change the background color here
      secondary: '#009688', // Change the icon color here if needed
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <GoogleOAuthProvider clientId="909968844049-uv33gt8o73ijr8ehsf8ucr8ho6uobrlj.apps.googleusercontent.com">
        <DndProvider backend={HTML5Backend}>
            {/* Pass custom toast styles to the Toaster component */}
            <Toaster toastOptions={{ style: customToastStyles }} />
            <LanguageProvider>
                <App />
            </LanguageProvider>
        </DndProvider>
    </GoogleOAuthProvider>
);
