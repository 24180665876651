import { Tabs } from 'antd';
import React, {useState} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import {MdCheckCircle, MdContentCopy, MdOutlinePlayCircleOutline} from 'react-icons/md'

const onChange = (key) => {
  console.log(key);
};

const CopyModalTabs = ({ uid, bid, type, link, copySuccess1, handleVideoModal, handleCopyToClipboard1, fbVarifyToken, fbCallbackUrl, handleCopyToClipboard2, copySuccess2, handleCopyToClipboard3, copySuccess3 }) => {

  const [copySuccess, setCopySuccess] = useState(false);

  const url = link?.match(/src=['"](.*?)['"]/)[1]
  const baseUrl = "https://www.aceva.com.my/botIframe/"
  // const newUrl = url?.replace(baseUrl, '')
  const newUrl = `https://aceva.com.my/botIframe/${uid}/${bid}`
 
  const handleCopyToClipboard = () => {
    // toast.success('Chatbot link copied');
    toast.success('Copied', {
      iconTheme: {
        primary: '#009688',
        secondary: '#ffffff',
      },
    });
    setCopySuccess(true);
 
    // You can set a timeout to reset the copySuccess state after a certain duration
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const items = [
    {
      key: '1',
      label: 'IFrame Code',
      children: (
        type !== 'whatsapp-bot' && (
          <div className="code-container">
            <label>Code</label>
            <div className="code-wrapper">
              <code>{link}</code>
            </div>
            <div className="code-actions">
              <CopyToClipboard
                onCopy={handleCopyToClipboard1}
                text={link}
              >
                <button className={`form-button code-btn ${copySuccess1 ? 'success' : ''}`}>
                  {copySuccess1 ? <MdCheckCircle /> : <MdContentCopy />}
                  <span>{copySuccess1 ? 'Copied!' : 'Copy Iframe'}</span>
                </button>
              </CopyToClipboard>

              {/* Video guide on how to embed the iframe */}
              <button onClick={() => handleVideoModal()} className="form-button button-outline code-btn code-btn-outline">
                <MdOutlinePlayCircleOutline />
                <span>How it works?</span>
              </button>
            </div>
          </div>
        )
      ),
    },
    // {
    //   key: '2',
    //   label: 'WordPress',
    //   children: (
    //     type !== 'whatsapp-bot' && <div className="copy-link-wrap copy-modal-tab-input">
    //       <label htmlFor="">WordPress API Key</label>
          
    //       <div className="form-group copy-link-group">
    //         <input type="text" value={newUrl} />
    //         <CopyToClipboard
    //               onCopy={handleCopyToClipboard}
    //               text={newUrl}
    //             >
    //               <button className={`form-button copy-site-link-btn ${copySuccess ? 'success' : ''}`}>
    //                 {copySuccess ? <MdCheckCircle /> : <MdContentCopy />}
    //                 <span>{copySuccess ? 'Copied!' : 'Copy API'}</span>
    //               </button>
    //             </CopyToClipboard>
    //           </div>
    //     </div>
    //   ),
    // },
    // {
    //   key: '3',
    //   label: 'Facebook',
    //   children: (
    //     <>
    //    { fbCallbackUrl &&  <div className="copy-link-wrap">
    //     <label htmlFor="">Messenger Callback URL</label>
        
    //     <div className="form-group copy-link-group">
    //       <input type="text" value={fbCallbackUrl} />
    //       <CopyToClipboard
    //             onCopy={handleCopyToClipboard2}
    //             text={fbCallbackUrl}
    //           >
    //             <button className={`form-button copy-site-link-btn ${copySuccess ? 'success' : ''}`}>
    //               {copySuccess2 ? <MdCheckCircle /> : <MdContentCopy />}
    //               <span>{copySuccess2 ? 'Copied!' : 'Copy URL'}</span>
    //             </button>
    //           </CopyToClipboard>
    //         </div>
    //   </div>}
    //   {fbVarifyToken &&  <div className="copy-link-wrap">
    //   <label htmlFor="">Messenger Verifiy Token</label>

    //   <div className="form-group copy-link-group">
    //     <input type="password" value={fbVarifyToken} />
    //     <CopyToClipboard
    //           onCopy={handleCopyToClipboard3}
    //           text={fbVarifyToken}
    //         >
    //           <button className={`form-button copy-site-link-btn ${copySuccess ? 'success' : ''}`}>
    //             {copySuccess3 ? <MdCheckCircle /> : <MdContentCopy />}
    //             <span>{copySuccess3 ? 'Copied!' : 'Copy Token'}</span>
    //           </button>
    //         </CopyToClipboard>
    //       </div>
    //   </div>}</>
    //   ),
    // }
  ];

  return <Tabs className='copy-modal-tabs' defaultActiveKey="1" items={items} onChange={onChange} />;
};

export default CopyModalTabs;
