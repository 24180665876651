import React from 'react'
import { Link } from 'react-router-dom'

function Breadcrumb({title, steps, stepLabel, stepUrl}) {
  return (
    <>
      <div className="breadcrumb">
        <li className='!text-sm font-medium'><Link to="/" className='text-brand-color'>Dashboard</Link></li>
        {
          steps && (
            <>
              <li>/</li>
              <li className='!text-sm font-medium'><Link to={stepUrl} className='text-brand-color'>{stepLabel}</Link></li>
            </>
          )
        }
        <li className='!text-sm'>/</li>
        <li className='!text-sm'>{title}</li>
      </div>
    </>
  )
}

export default Breadcrumb