import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { VscMenu } from "react-icons/vsc";
import { Link, useNavigate } from 'react-router-dom';
import { apiUrl } from '../Variables';
import logo from '../images/aceva.png';
import HeaderDropdown from './antd/HeaderDropdown';
import SidebarDrawer from './antd/SidebarDrawer';

function Header({ isMobile, toggleLoggedInState, mobileSidebar, setMobileSidebar}) {

  const [dropdownToggle, setDropdownToggle] = useState(false)
  const [mode, setMode] = useState(false)
  const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light-theme')
  const [userDetails, setUserDetails] = useState({})
  const [users, setUsers] = useState([])
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
  // console.log(userInfo)

  const navigate = useNavigate()

  /* Method that will fix header after a specific scrollable */
  const scrollHandler = () => {
      const header = document.querySelector('header');
      const scrollTop = window.scrollY;
      (scrollTop >= 200) ? header.classList.add('active') : header.classList.remove('active');
  }

  useEffect(() => {
    if(isMobile) {
      window.addEventListener('scroll', scrollHandler)
        return () => {
          window.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [isMobile , userInfo])

  // get user details
  useEffect(() => {
    const fetchUser = async () => {
      try {

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };

        const {data} = await axios.get(`${apiUrl}/user/${userInfo?._id}`, {headers})
        setUserDetails(data)

        const {data:userData} = await axios.get(`${apiUrl}/user`, {headers})
        setUsers(userData)

      } catch (error) {
        console.log('user fetch fail:', error)
      }
    }
    fetchUser();
  },[])

  // logout handler
  const handleLogout = async () => {

    // Clear the local storage
    await localStorage.clear();
    if (typeof toggleLoggedInState === 'function') {
      toggleLoggedInState()
    }
    navigate('/login')
      
  }

  const toggleTheme = () => {
    setMode(!mode)
    if(theme == 'light-theme') {
      localStorage.setItem('theme', 'dark-theme')
      const dark = localStorage.getItem('theme')
      setTheme(dark)
    } else {
      localStorage.setItem('theme', 'light-theme')
      const light = localStorage.getItem('theme')
      setTheme(light)  
    }
  }

  useEffect(() => {
    document.body.className = theme; 
  }, [theme, mode])

  // console.log('userInfo from header:', userInfo)
  
  return (
    <>
      <header>
        {/* header option */}
        <div className="header-option">

          {/* burger menu */}
            <SidebarDrawer />

          
                <Link to="/dashboard">
                  <div className="header-logo-wrap">
                      <div className="header-sidebar-logo">
                        <img src={logo} alt="logo" />
                      </div>
                    {/* <h2 className="header-site-title">{settings?.siteName}</h2> */}
                  </div>
                </Link>

          {/* <div className="mode" onClick={toggleTheme}>
            { mode ? <MdDarkMode />  : <MdOutlineLightMode /> }
          </div> */}
          
        </div>
        {/* header profile */}
        <div className="header-profile" onClick={() => setDropdownToggle(!dropdownToggle)}>

          {/* navbar */}
          {/* <div className="header-nav">
            <ul>
              <Link to="/chatlogs">
                <li>
                  <MdOutlineMessage />
                  <span>Chatlogs</span>
                </li>
              </Link>
              
              <Link to="/billing">
                <li>
                  <BsCreditCard2Back />
                  <span>Billing</span>
                </li>
              </Link>
            </ul>
          </div> */}

          {/* plan and action button */}
          <div className="plan-and-action-button-wrap">
            {/* progress bar */}
            <div className="upgrate-progress">
              <p>Current Plan: <span className="brand-color">{userInfo?.meta_data?.planData?.name}</span></p>
              {/* <ProgressBar 
                height={5} 
                completed={20}
                isLabelVisible={false}
                bgColor="#009688"
              /> */}
            </div>

            {/* upgrate button */}
            <Link to="/billing">
              <button className='upgrate-btn'>
                <svg viewBox="4.000374794006348 0 16.399250030517578 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.398 3.083a1 1 0 01.594 1.041l-.66 5.276H19.4a1 1 0 01.768 1.64l-8 9.6a1 1 0 01-1.76-.764l.66-5.276H5a1 1 0 01-.768-1.64l8-9.6a1 1 0 011.166-.277z" fill="currentColor"></path></svg>
                <span>Upgrade</span>
              </button>
            </Link>
          </div>

          {/* profile */}
          <div className='relative'>
            <div className='header-logo-wrap new-header-logo-wrap'>
              {
                // userDetails?.profile_image && (
                //   <div className='header-logo'>
                //     <img src={userDetails?.profile_image ? `${userDetails?.profile_image}` : 'https://cdn3d.iconscout.com/3d/premium/thumb/trendy-person-avatar-6299537-5187869.png'} alt="header" />
                //   </div>
                // )
              }
              
              <div className='header-logo-content'>

                  {
                  // userDetails?.first_name ? <p>{userDetails?.first_name} {userDetails?.last_name}</p> : <p>Profile</p>
                  } 

                  {/* { dropdownToggle ? <BsChevronUp /> : <BsChevronDown /> } */}
                  {/* {userDetails?.first_name && userDetails?.role && <p className="role-badge">{userDetails?.role}</p>}  */}
                </div>
            </div>

            {/* dropdown */}
            <HeaderDropdown handleLogout={handleLogout} />
            {
              // dropdownToggle && (
            //     <div className="dropdown-wrapper">
            //   <ul>
            //     <Link to="/settings" className={({ isActive }) =>isActive ? "active-link" : ""}>
            //       <li>
            //         <IoSettingsOutline />
            //         <span>Settings</span>
            //       </li>
            //     </Link>
            //     <Link to="/chatlogs" className={({ isActive }) =>isActive ? "active-link" : ""}>
            //       <li>
            //         <BsChatDots />
            //         <span>Chatlogs</span>
            //       </li>
            //     </Link>
                
            //     <Link onClick={handleLogout} to="/logout">
            //       <li className='logout-btn'>
            //         <MdLogout />
            //         <span>Logout</span>
            //       </li>
            //     </Link>
            //   </ul>
            // </div>
            //   )
            }
          </div>

        </div>

      </header>
    </>
  )
}

export default Header